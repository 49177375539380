@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.navMenu {
  justify-content: center;
  border: transparent !important;
  min-width: 100px !important;
}

.navMenuItem {
  font-size: 1.4rem;
  padding: 0 0.9rem !important;

  @include mq(lg) {
    font-size: 1.6rem;
  }

  & span[class$='ant-menu-title-content'] a {
    color: $text-color-primary !important;
  }

  &:hover span[class$='ant-menu-title-content'] a {
    color: $text-color-selected !important;
  }

  &[class*='ant-menu-item-selected'],
  &[class*='ant-menu-item-selected'] {
    color: $text-color-selected !important;
    & span[class$='ant-menu-title-content'] {
      color: $text-color-selected !important;
    }
  }

  &::after {
    right: 0.9rem !important;
    left: 0.9rem !important;
  }
}

.navMenuItemActive {
  @extend .navMenuItem;

  & span[class$='ant-menu-title-content'] {
    color: $text-color-selected !important;
  }

  &::after {
    right: 0.9rem !important;
    left: 0.9rem !important;
    border-bottom: 2px solid $text-color-selected !important;
  }
}
