@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.modalPadding {
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
}

.modal {
  padding: 0 !important;
  top: 0 !important;
  margin: 0 !important;
  max-width: 100vw !important;

  & div[class$='ant-modal-content'] {
    border-radius: 0 !important;
    background: $background-color-main !important;

    & button[class$='ant-modal-close'] {
      right: 3.6rem !important;

      @include mq(lg) {
        right: 0 !important;
      }
    }

    & div[class$='ant-modal-header'] {
      @extend .modalPadding;

      & div[class$='ant-modal-title'] {
        display: flex !important;

        & svg {
          margin-right: 0.8rem !important;
        }
      }
    }

    & div[class$='ant-modal-body'] {
      @extend .modalPadding;
      height: calc(100vh - 7.3rem) !important;
      padding: 4rem 5.5rem;

      @include mq(lg) {
        height: 100% !important;
        min-height: 100vh !important;
        padding: 1.8rem !important;
      }
    }

    & div[class$='ant-modal-footer'] {
      @extend .modalPadding;
    }
  }
}
