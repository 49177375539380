@use 'styles/utils/variables' as *;

.statusTag {
  padding: 0.3rem 0.7rem !important;

  &[class*='ant-tag ant-tag-success'] {
    color: $text-color-success !important;
    background: $background-color-success !important;
    border-color: $background-color-success !important;
  }

  &[class*='ant-tag-error'] {
    color: $text-color-error !important;
    background: $background-color-error !important;
    border-color: $background-color-error !important;
  }

  &[class*='ant-tag-warning'] {
    color: $text-color-warning !important;
    background: $background-color-warning !important;
    border-color: $background-color-warning !important;
  }

  &[class*='ant-tag-processing'] {
    color: $text-color-selected !important;
    background: $background-color-selected !important;
    border-color: $background-color-selected !important;
  }

  &[class*='ant-tag ant-tag-blue'] {
    color: $text-color-blue !important;
    background: $background-color-blue !important;
    border-color: $background-color-blue !important;
  }

  &[class*='ant-tag ant-tag-default'] {
    color: $text-color-primary !important;
    background: $background-color-input !important;
    border-color: $background-color-input !important;
  }
}

.disabled {
  &[class*='ant-tag'] {
    color: $text-color-disabled;
    background: $background-color-disabled !important;
    border-color: $background-color-disabled !important;
  }
}
