@use 'styles/utils/variables' as *;

input.formInput {
  background: $background-color-input;
  border-color: $border-color-input;
  border-radius: 3px;
  font-weight: 600;

  &::placeholder {
    color: $text-color-placeholder;
    font-weight: normal;
    font-size: 1.4rem;
  }
}

span.formInputHasAffix {
  background-color: $background-color-input;
  border-color: $border-color-input;
  border-radius: 3px;
  font-weight: 600;

  &[class*='ant-input-affix-wrapper-focused'] {
    border-color: $border-color-primary-hover;
  }

  & input[type='text'] {
    background: transparent;

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
}
