@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.authBtn {
  border-color: transparent !important;
  margin-right: 2rem;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0 !important;
  padding-right: 0 !important;

  & span {
    color: $text-color-primary !important;
    font-size: 1.6rem;
  }

  @include mq(lg) {
    & svg {
      margin-right: 1rem;
    }
  }
}

.settingBtn {
  @extend .authBtn;

  justify-content: center;

  & svg {
    margin-right: 1rem;
  }
}

.timeFormatPopover {
  div[class='ant-popover-inner-content'] {
    padding: 0.5rem;
  }
}

.timeFormatBtn {
  @extend .authBtn;

  justify-content: center;
  margin-right: 0;
}

.actionBtn {
  border: none !important;

  &:hover {
    background: transparent;
  }
}

.userInfoWrap {
  display: flex;
  align-items: center;
  padding-bottom: 0;

  @include mq(lg) {
    padding-bottom: 1rem;
  }
}

.avatar {
  background: linear-gradient(180deg, #5e6d93 0%, #2e3a59 100%) !important;
  border: 1.5px solid $background-color-input;
  display: flex !important;
  align-content: center;
  justify-content: center;
  align-items: center;
  @include mq(xs) {
    font-size: 1.1rem !important;
    width: 3.5rem !important;
    height: 3.5rem !important;
    font-size: 1.2rem !important;
  }
}

.fullNameAndRole {
  display: flex;
  flex-direction: column;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  font-size: 1.4rem !important;
}

.langSelector {
  & div[class='ant-select-selector'] {
    font-weight: normal;
    font-size: 1.2rem !important;
    padding: 0 0.5rem !important;
    border: 0.5px solid $border-color-input-light !important;

    & span[class='ant-select-selection-item'] {
      padding-right: 1.7rem;
    }
  }

  & span[class='ant-select-arrow'] {
    right: 0.5rem;
    margin-top: -0.4rem;
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
  }
}

.langItem {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem !important;
}

.timeFormatSelect {
  & div[class='ant-select-selector'] {
    font-weight: normal;
    font-size: 1.2rem !important;
    border: 0.5px solid $border-color-input-light !important;
  }
}

.colWithSettings {
  display: flex;
  justify-content: flex-start;
}

.status {
  color: $text-color-selected !important;
}

.verifyEmailButton {
  padding-left: 5px !important;
}

.resendField {
  width: 100% !important;
}
