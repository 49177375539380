@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.table {
  height: 100% !important;

  div[class$='ant-spin-container ant-spin-blur'] {
    height: 100%;
  }

  div[class$='ant-spin ant-spin-spinning'] {
    min-height: 100%;
    backdrop-filter: blur(0.4rem);
    background: rgba(255, 255, 255, 0.4);

    & span[class$='ant-spin-dot ant-spin-dot-spin'] {
      font-size: 4rem;
    }
  }

  div[class$='ant-spin-nested-loading'],
  div[class$='ant-spin-container'],
  div[class$='ant-table ant-table-empty'],
  div[class$='ant-table-container'] {
    height: 100% !important;
  }

  div[class$='ant-table ant-table-empty'] {
    border: 0.5px solid $border-color-input-light;
    border-radius: 5px;

    tr td[class$='ant-table-cell']:last-child {
      border-bottom: none !important;
    }
  }

  div[class*='ant-table-empty'] {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.8rem !important;
      height: 1rem !important;
    }
  }

  div[class$='ant-table'] {
    border: 0.5px solid $border-color-input-light;
    border-radius: 5px;
    height: calc(100% - 4.8rem) !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem !important;
      height: 1rem !important;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar-track;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbar-thumb;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $scrollbar-thumb-hover;
    }
  }

  thead[class$='ant-table-thead'] {
    position: sticky;
    top: 0;
    z-index: 100;

    tr th[class*='ant-table-cell'],
    tr th[class*='ant-table-column-has-sorters'],
    tr th[class*='ant-table-cell-ellipsis'],
    tr th[class*='ant-table-column-sort'] {
      color: $text-color-secondary-grey;
      font-weight: normal;
      background: $background-color-main;

      &:hover {
        background: $background-color-main-active;
      }

      @include mq(sm) {
        font-size: 1.2rem;
      }
    }
  }

  tbody[class$='ant-table-tbody'] {
    tr td[class*='ant-table-cell'],
    tr td[class*='ant-table-column-has-sorters'],
    tr td[class*='ant-table-cell-ellipsis'],
    tr td[class*='ant-table-column-sort'] {
      background-color: $background-color-secondary;

      @include mq(sm) {
        font-size: 1.2rem;
      }
    }

    tr:hover td {
      background: #fbfbfb;
    }

    tr[class='ant-table-placeholder']:hover td {
      background: $background-color-secondary;
    }
  }

  tr th[class$='ant-table-cell ant-table-column-has-sorters']:first-child {
    border-top-left-radius: 5px !important;
  }

  tr th[class$='ant-table-cell']:first-child {
    border-top-left-radius: 5px !important;
  }

  tr th[class$='ant-table-cell ant-table-column-has-sorters']:last-child {
    border-top-right-radius: 5px !important;
  }

  tr th[class$='ant-table-cell']:last-child {
    border-top-right-radius: 5px !important;
  }

  tr td[class*='ant-table-cell'],
  tr td[class*='ant-table-column-sort'],
  tr td[class*='ant-table-cell-ellipsis'] {
    padding: 1.2rem 1.6rem;

    & a {
      color: $text-color-primary;
    }
  }

  ul[class$='ant-pagination ant-table-pagination ant-table-pagination-left'] {
    margin-bottom: 0 !important;
  }
}

.tableWithLink {
  tr td[class*='ant-table-cell'],
  tr td[class*='ant-table-column-sort'],
  tr td[class*='ant-table-cell-ellipsis'] {
    padding: 0 !important;
  }

  tr td[class*='ant-table-cell ant-table-cell-ellipsis'] a,
  tr td[class*='ant-table-column-sort'] a {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}
