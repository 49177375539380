@use 'styles/utils/variables' as *;

.label {
  color: $text-color-primary;
  font-weight: 600;
  font-size: 1.2rem !important;
  line-height: 1.3 !important;
  display: inline-flex;
  margin-bottom: 1.06rem;
}

.formItem {
  margin-bottom: 0rem !important;
}

.uploaderContainer {
  height: 13rem;
  width: 100%;
  padding: 0.8rem;
  padding-bottom: 0.2rem;
  border: 0.5px solid $border-color-input;
  border-radius: 3px;
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
