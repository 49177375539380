@use 'styles/utils/variables' as *;

.css-1okebmr-indicatorSeparator {
  display: none;
}

.timezone-select {
  .css-qc6sy-singleValue {
    font-weight: 600;
  }

  & .css-1s2u09g-control,
  & .css-1pahdxg-control,
  & .css-13cymwt-control {
    background-color: $background-color-input !important;
    border-color: $border-color-input;
    min-height: 4.1rem;
    transition: all 300ms;

    & div[class$='ValueContainer'] {
      & div[class$='-singleValue'] {
        color: $text-color-primary;
        font-weight: 600;
      }

      & div[class$='-Input'] {
        margin: 0;
        padding: 0;
      }

      & div[class$='-placeholder'] {
        color: $text-color-placeholder;
        font-weight: normal;
        font-size: 1.4rem;
      }
    }

    &:hover {
      cursor: pointer;
      border-color: $border-color-primary-hover;
    }

    &:focus {
      border-color: $border-color-primary-hover;
      box-shadow: $box-shadow-color-active;
    }
  }

  & .css-1pahdxg-control {
    border-color: $border-color-primary;
    box-shadow: $box-shadow-color-active;
  }

  & div[class$='-menu'] > div {
    & div[class$='-option'] {
      cursor: pointer;
    }
  }

  &.large {
    & div[class$='ValueContainer'] {
      padding: 0 0.9rem;
      font-size: 1.6rem;
      height: 3.8rem;
    }

    & div[class$='indicatorContainer'] {
      padding: 0 1.1rem;
    }
  }

  &.middle {
    & div[class$='ValueContainer'] {
      padding: 0 1.1rem;
      font-size: 1.4rem;
      height: 3rem;
    }

    & div[class$='indicatorContainer'] {
      padding: 0 1.1rem;
    }
  }

  &.small {
    & div[class$='ValueContainer'] {
      padding: 0 0.7rem;
      font-size: 1.4rem;
      height: 2.2rem;
    }

    & div[class$='indicatorContainer'] {
      padding: 0 0.7rem;
    }
  }
}
