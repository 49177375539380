@use 'styles/utils/mixins' as *;

.formItem {
  margin-bottom: 0 !important;
}

.footerWrap {
  display: flex !important;
  justify-content: space-between !important;

  @include mq(sm) {
    flex-direction: column;
  }
}

.footerBtn {
  width: 12.7rem !important;
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;

  @include mq(sm) {
    width: 84vw !important;
  }
}
