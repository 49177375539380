@use 'styles/utils/variables' as *;

.menuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;

  & svg {
    color: $text-color-primary;
  }
}

.sidebarMenu {
  div[class$='ant-drawer-body'] {
    padding: 1.6rem 2.4rem;
  }
}

.divider {
  margin: 1.4rem 0 !important;
}
