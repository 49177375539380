@use 'styles/utils/variables' as *;

span.ant-typography {
  color: $text-color-primary;
  font-weight: normal;

  &.strong {
    font-weight: 600;
  }

  &.small {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &.middle {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &.large {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  &.ant-typography-secondary {
    color: $text-color-secondary-dark;
  }

  &.ant-typography-success {
    color: $text-color-success;
  }

  &.ant-typography-warning {
    color: $text-color-warning;
  }

  &.ant-typography-danger {
    color: $text-color-error;
  }
}
