@use 'styles/utils/variables' as *;

.footer {
  border-top: 1px solid $border-color-secondary-light;
  padding: 2rem 5rem !important;
}

.support {
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
  }
}

.linkWrap {
  margin-bottom: 0 !important;
  margin-right: 3rem;
  margin-left: 3rem;

  & a {
    color: $text-color-primary !important;
    font-size: 1.2rem;
    line-height: 1.6rem;
    transition: all 300ms linear;

    &:hover,
    &:focus {
      color: $text-color-secondary-hover !important;
    }
  }
}
