@use 'styles/utils/mixins' as *;

.contentWrap {
  height: 100% !important;
  height: calc(100vh - 10rem) !important;
}

.content {
  text-align: center;
}

.title {
  font-size: 2.8rem !important;
  line-height: 1.2 !important;
  @include mq(sm) {
    font-size: 1.8rem !important;
  }
}

.text {
  font-size: 1.8rem !important;
  @include mq(sm) {
    font-size: 1.5rem !important;
  }
}

.signOutBtn {
  margin-left: 1rem;
}
