@use 'styles/utils/variables' as *;

.uploader {
  flex: 1;

  & div[class$='ant-upload ant-upload-select ant-upload-select-text'] {
    width: 100% !important;
  }
  & div[class$='ant-upload ant-upload-select ant-upload-select-text ant-upload-disabled'] {
    width: 100% !important;
  }
}

.buttonUploaderContainer {
  height: 11.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $background-color-input;
  border: 0.5px dashed $border-color-input;
  border-radius: 3px;
}

.buttonUploader {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border: none !important;
  font-size: 1.2rem;
  line-height: 1.3;
  background: transparent;

  &[class*='ant-btn-text']:hover {
    background: transparent;
  }
}

.buttonUploaderBoldText {
  font-weight: 600;
}

.imageContainer,
.loadingContainer {
  display: flex;
  width: 12.4rem;
  height: 11.9rem;
  position: relative;
  margin-right: 0.8rem;
  padding-bottom: 0.8rem;
}

.image {
  width: 100%;
  height: 100%;
}

.basketContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.2rem;
  height: 3.2rem;
  bottom: 0.8rem;
  right: 0;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 3px;
}
