@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.header {
  border: 1px solid $border-color-secondary-light;
  line-height: 6.3rem !important;
  padding: 0 4rem !important;

  @include mq(xl) {
    padding: 0 1.2rem !important;
    display: flex;
    align-items: center;
  }
}

.authBtn {
  border-color: transparent !important;
  margin-right: 2rem;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0 !important;
  padding-right: 0 !important;

  & span {
    color: $text-color-primary !important;
    font-size: 1.6rem;
  }

  @include mq(lg) {
    & svg {
      margin-right: 1rem;
    }

    margin-right: 1rem;
  }
}

.logoWrap {
  display: flex;
  align-items: center;
  margin-left: 10px;

  @include mq(lg) {
    margin-right: 5px;
  }
}

.logo {
  vertical-align: middle;
}

.authMenuWrap {
  @extend .logoWrap;
  justify-content: flex-end;
}

.menuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;

  & svg {
    color: $text-color-primary;
  }
}

.headerWrap {
  width: 100%;
  height: 100%;
}

.settingBtn {
  @extend .authBtn;

  justify-content: center;

  & svg {
    margin-right: 1rem;
  }
}

.actionBtn {
  border: none !important;

  &:hover {
    background: transparent;
  }
}

.userInfoWrap {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  margin-left: 1rem;

  @include mq(lg) {
    padding-bottom: 1rem;
  }
}

.avatar {
  background: linear-gradient(180deg, #5e6d93 0%, #2e3a59 100%) !important;
  border: 1.5px solid $background-color-input;
  display: flex !important;
  align-content: center;
  justify-content: center;
  align-items: center;
  @include mq(xs) {
    font-size: 1.1rem !important;
    width: 3.5rem !important;
    height: 3.5rem !important;
    font-size: 1.2rem !important;
  }
}

.fullNameAndRole {
  display: flex;
  flex-direction: column;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  font-size: 1.4rem !important;
}

.status {
  color: $text-color-selected !important;
}

.rightSideMenu {
  display: flex;
  justify-content: flex-end;
}

.leftSideMenuContainer {
  display: flex;
}

.helpLink {
  color: $text-color-primary !important;
  &:hover {
    color: $text-color-selected !important;
  }
}
