@use 'styles/utils/variables' as *;
@use 'styles/utils/mixins' as *;

.menuContainer {
  margin-bottom: 2.4rem;
}

.pageHeader {
  height: auto !important;
  line-height: 1 !important;
  padding: 0 0 0.8rem 0 !important;
}

.title {
  white-space: break-spaces !important;

  @include mq(sm) {
    font-size: 1.6rem !important;
  }
}

.navMenu {
  background: $background-color-secondary !important;
  border: 0.5px solid $border-color-input-light !important;
  border-radius: 3px !important;
  padding: 0.4rem !important;
  line-height: 2.24rem !important;
  overflow: hidden !important;

  @include mq(lg) {
    margin-top: 1rem !important;
  }
}

.navMenuItem {
  font-size: 1.2rem;
  margin-top: 0 !important;
  top: 0 !important;
  display: block !important;

  & span[class$='ant-menu-title-content'] a {
    color: $text-color-sub-nav;
  }

  &[class*='ant-menu-item-selected'] {
    background: $background-color-selected !important;
    border-radius: 2px !important;

    & span[class$='ant-menu-title-content'] a {
      color: $text-color-selected !important;
    }
  }

  &::after {
    border: none !important;
  }
}

.navMenuItemDesktop {
  @extend .navMenuItem;
  // Needed to prevent the menu from collapsing
  opacity: 1 !important;
  height: auto !important;
  overflow-y: visible !important;
  position: relative !important;
  pointer-events: all !important;
}

.menuWrapper {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
}

.titleBlockContainer {
  flex: 1;
  margin-left: 1rem;
  font-size: 1.5rem;
}
