@use 'styles/utils/variables' as *;

.linkWrap {
  & span {
    display: flex;
    align-items: center;
  }

  & a {
    color: $text-color-placeholder;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
