// UTILS
@use 'utils/variables';
@use 'utils/mixins';

// BASE
@use 'base/base';

// fix problem "ResizeObserver loop completed with undelivered notifications in dev mode"
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
