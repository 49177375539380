@use 'styles/utils/variables' as *;

.textAreaInput {
  background-color: $background-color-input !important;
  font-weight: 600;

  & textarea[class$='ant-input'],
  & textarea[class$='ant-input ant-input-lg'],
  & textarea[class$='ant-input ant-input-sm'] {
    background-color: $background-color-input !important;
    font-weight: 600;

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }

  &::placeholder {
    color: $text-color-placeholder;
    font-weight: normal;
    font-size: 1.4rem;
  }
}
