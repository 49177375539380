@use 'styles/utils/variables' as *;

.formItemHasError {
  background: $background-color-error !important;
  border-color: $border-color-error !important;
}

.formItem {
  width: 100%;

  &[class*='ant-form-item-has-error'] [class^='ant-picker'],
  &[class*='ant-form-item-has-error']
    div[class*='timezone-select']
    div[class*='css-yk16xz-control'] {
    @extend .formItemHasError;

    &[class*='ant-picker-focused'] {
      @extend .formItemHasError;
    }

    &[class*='ant-picker']:hover {
      @extend .formItemHasError;
    }
  }
}

.formItemLabel {
  & div[class$='ant-form-item-label'] {
    line-height: 1.3;

    & label {
      color: $text-color-primary;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}
